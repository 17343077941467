import Typography from "@/styled/Typography"
import { SectionContainer } from "@/styled/Grid"
import { filter } from "lodash"
import * as PropTypes from "prop-types"
import Cards from "@/components/Cards"

export default function Treatments({ title, exclude, page, long }) {
  const list = filter(treatments, t => ![...exclude, "presoterapia", "nesa-xsignal"].includes(t.id))

  return (
    <SectionContainer>
      <Typography variant="title2" className="mb-2" $weight="medium" center>
        {title}
      </Typography>
      <Typography className="mb-5" center>
        Los mejores tratamientos con la última tecnología
      </Typography>
      <Cards list={list} page={page} long={long} />
    </SectionContainer>
  )
}

Treatments.defaultProps = {
  title: "Nuestros tratamientos",
  exclude: [],
  long: false
}

Treatments.propTypes = {
  title: PropTypes.string,
  exclude: PropTypes.array,
  long: PropTypes.bool,
  page: PropTypes.string.isRequired
}

const treatments = [
  {
    id: "fisioterapia",
    title: "Terapia manual",
    url: "/tratamientos/fisioterapia",
    description:
      "La terapia manual es una técnica de fisioterapia que utiliza manipulaciones, movilizaciones y masajes para tratar el dolor y la disfunción musculoesquelética, mejorar la circulación y reducir la inflamación en diferentes partes del cuerpo.",
    image:
      "https://res.cloudinary.com/physio-wow/image/upload/ar_1.0,c_crop,w_auto,q_auto,f_auto/static/tratamientos/terapia-manual"
  },
  {
    id: "diatermia",
    title: "Diatermia",
    url: "/tratamientos/diatermia",
    description:
      "La diatermia profunda es un tratamiento de fisioterapia que utiliza ondas electromagnéticas para generar calor en los tejidos profundos del cuerpo.",
    image:
      "https://res.cloudinary.com/physio-wow/image/upload/ar_1.0,c_crop,w_auto,q_auto,f_auto/static/tratamientos/diatermia_cmrcay"
  },
  {
    id: "puncion-seca",
    title: "Punción Seca",
    url: "/tratamientos/puncion-seca",
    description:
      "La punción seca es una técnica de fisioterapia invasiva que consiste en la inserción de una aguja de acupuntura en un punto gatillo muscular para aliviar el dolor y la tensión muscular.",
    image:
      "https://res.cloudinary.com/physio-wow/image/upload/ar_1.0,c_crop,w_auto,q_auto,f_auto/static/tratamientos/puncion-seca"
  },
  {
    id: "nesa-xsignal",
    title: "Nesa Xsignal",
    url: "/tratamientos/nesa-xsignal",
    description: "",
    image:
      "https://res.cloudinary.com/physio-wow/image/upload/ar_1.0,c_crop,w_auto,q_auto,f_auto/static/tratamientos/nesa"
  },
  {
    id: "presoterapia",
    title: "Presoterapia",
    url: "/tratamientos/presoterapia",
    description: "",
    image:
      "https://res.cloudinary.com/physio-wow/image/upload/ar_1.0,c_crop,w_auto,q_auto,f_auto/static/tratamientos/presoterapia"
  },
  {
    id: "fisioterapia-deportiva",
    title: "Fisoterapia Deportiva",
    url: "/tratamientos/fisioterapia-deportiva",
    description:
      "La fisioterapia deportiva se enfoca en prevenir, evaluar y tratar lesiones deportivas, así como en optimizar el rendimiento deportivo a través del entrenamiento específico de fuerza, flexibilidad y resistencia.",
    image:
      "https://res.cloudinary.com/physio-wow/image/upload/ar_1.0,c_crop,w_auto,q_auto,f_auto/static/tratamientos/fisioterapia-deportiva"
  },
  {
    id: "electropuncion",
    title: "Electropunción",
    url: "/tratamientos/electropuncion",
    description:
      "La electropunción es una técnica de fisioterapia invasiva que combina la inserción de agujas de acupuntura con estimulación eléctrica para aliviar el dolor y la tensión muscular.",
    image:
      "https://res.cloudinary.com/physio-wow/image/upload/ar_1.0,c_crop,w_auto,q_auto,f_auto/static/tratamientos/electropuncion"
  },
  {
    id: "magnetoterapia",
    title: "Magnetoterapia",
    url: "/alquiler-magnetoterapia",
    description:
      "La magnetoterapia es un tratamiento de fisioterapia no invasivo que utiliza campos magnéticos para reducir el dolor, la inflamación y acelerar la recuperación de tejidos dañados.",
    image:
      "https://res.cloudinary.com/physio-wow/image/upload/ar_1.0,c_crop,w_auto,q_auto,f_auto/static/alquiler/magnetofield"
  }
]
