import styled from "styled-components"
import Link from "next/link"
import { sendEvent } from "@/scripts/GoogleAnalytics"
import Image from "next/image"
import Col from "@/styled/Grid/Col"
import Typography from "@/styled/Typography"

const StyledCard = styled(Link)`
  display: block;
  border-radius: 8px;
  box-shadow: 0 2px 14px 0 rgba(195, 205, 224, 0.8);
  background: ${({ theme }) => theme.colors.white};
  padding: 20px;
  cursor: pointer;
  height: 100%;
`

const ImageWrapper = styled.div`
  height: 180px;
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
  img {
    object-fit: cover;
  }
`

export default function Card({ item, page, long }) {
  const { id, title, image, url, description } = item

  const onClickCta = () => {
    sendEvent("card", { event_category: page, event_label: id })
  }

  return (
    <Col xs={12} sm={6} lg={long ? 6 : 4} xl={long ? 4 : 3} className="mb-4">
      <StyledCard href={url} passHref onClick={onClickCta}>
        <ImageWrapper>
          <Image alt={title} src={image} fill />
        </ImageWrapper>
        <Typography variant="title3" className="mt-3 mb-0" $weight="medium" center>
          {title}
        </Typography>
        {long && (
          <Typography variant="small" className="mt-3 mb-0" style={{ textAlign: "justify" }}>
            {description}
          </Typography>
        )}
      </StyledCard>
    </Col>
  )
}
