import { Row } from "@/styled/Grid"
import Card from "@/components/Cards/Card"

export default function Cards({ list, page, long }) {
  return (
    <Row>
      {list.map(item => (
        <Card key={item.id} item={item} page={page} long={long} />
      ))}
    </Row>
  )
}
